@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Archivo+Narrow&family=Roboto&display=swap');

@media only screen and (min-width:360px){
    .movers-fileuploader{
        padding:4px;
        margin-top:8px;
        border:2px solid #eaeaea;
        color:#1da179;
        text-align: center;
    }
    .moversfileicon{
        padding-top:24px;
        display: flex;
        justify-content: center;
        cursor: pointer;
    }

    .movers-btn{
        background-color: #1da179 !important;
        font-family: 'Archivo Narrow';
        font-size: 19px;
        margin-bottom:8px;
    }

    .moverslogin{
        width:100%;
        display:flex;
        flex-direction: column;
        justify-content: center;
    }
}

@media only screen and (min-width:768px){
    .movers-fileuploader{
        padding:48px;
        margin-top:8px;
        border:2px solid #eaeaea;
        color:#1da179;
        text-align: center;
    }

    .moversfileicon{
        display: flex;
        justify-content: center;
        cursor: pointer;
    }

    .movers-btn{
        background-color: #1da179 !important;
        font-family: 'Archivo Narrow';
        font-size: 19px;
    }

    .moverslogin{
        width:40%;
        display:flex;
        flex-direction: column;
        justify-content: center;
    }
}

/* width */
::-webkit-scrollbar {
    width:8px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #24dba4;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #1da179;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background:#1da179;
  }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

*{
    font-family: 'Roboto', sans-serif;
}

input:focus, textarea:focus{
    outline:none !important;
    box-shadow:none !important;
    border:1px solid #1a775b !important;
}

.bg-primary{
    background-color: #1da179!important;
}

.btn.btn-primary{
    background-color:#24dba4;
    border:1px solid #1da179;
}

.btn.btn-primary:focus{
    background-color:#1da179;
    border:1px solid #1da179;
}

.active.btn.btn-primary{
    color: #fff !important;
    background-color: #1da179!important;
}

a.btn.btn-primary{
    background-color:2px solid #1a775b !important;
    font-family: 'Archivo Narrow', sans-serif;
}

.navbar-toggler-icon:focus, .navbar-toggler:focus{
    outline:none !important;
    box-shadow: none;
}

.navbar-toggler-icon{
    color:#fff !important;
    ;
}

.navbar-toggler{
    border-color:#fff !important;
    
}

.navbar-toggler.collapsed{
    border-color:#fff !important;
}

.nav-link .nav-link.active{
    font-size:20px !important;
}

.active.nav-link.active{
    color:#fff;
    background-color:#1da179;
    border:1px solid #1da179;
}

.navbar-brand{
    color: #fff !important;
    font-family: 'Archivo Narrow', sans-serif;
}

.navbar.navbar-expand-lg.navbar-light.bg-light{
    background-color: #24dba4!important;
}

td{
    width:50%;
}

.moversjumbo{
    height:50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size:cover;
    position: relative;
}

.moversjumbotext{
    text-align: center;
    padding: 180px;
    color:'#fff';
}

.moversjumbomobile{
    width: 100%;
    height:30vh;
    background-size: cover;
    background-position: center, center;
    position: relative;
    object-fit: contain;
}

.moversjumbotextmobile{
    color:#fff;
    position: absolute;
    top:40%;
    left:18%;
}

.moversheaders{
    background-color:#24dba4;
    margin:10px;
    color:#fff;
    font-weight:400;
    font-family:'Archivo Narrow';
}

.servicesbox{
box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    color:#fff;
    background-color: #24dba4;
    margin:6px;
    width:25%;
    border-radius:5px;
    position:absolute;
}

.contactbox{
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    color:#fff;
    background-color: #24dba4;
    padding:8px;
    text-align: center;
    bottom:5%;
    left:42%;
    font-family:'Archivo Narrow';
    position:absolute;
}

.contactbox-mobile{
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    color:#fff;
    background-color: #24dba4;
    text-align: center;
    top:8%;
    left:40%;
    font-family:'Archivo Narrow';
    position:absolute;
}

.servicebox-mobile{
    top:7%;
    left:30%;
    font-family:'Archivo Narrow';
    position:absolute;
}

.link-primary{
    color: #fff !important;
    text-decoration: none;
    font-family:'Archivo Narrow';
}

.link-primary:hover{
    color:#eaeaea !important;
}

.servicesbox-mobile{
        color:#fff;
        background-color: #24dba4;
        border-radius:5px;
    }

.removalsfooter{
    color:#fff;
    padding:8px;
    background-color:#24dba4;
}

ul > li{
    padding:4px 0px 0px 4px;
    font-size: 18px;
}

ul > li > a{
    text-decoration: none;
    color:#fff;
}

ul > li > a:hover{
    color:#eaeaea;
}

.contactjumbo1{
    padding:16px;
}

.contactjumbo1 > div{
    flex:1;
}

.contactbox-mini{
    padding: 16px;
    width:350px;
    border-radius:5px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 8px, rgba(0, 0, 0, 0.22) 0px 3px 3px;
    border-top:6px solid #1da179;
}

.contactbox-mini-mobile{
    padding: 16px;
    width:100%;
    border-radius:5px;
    background-color: rgba(255, 255, 255, 0.699);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 8px, rgba(0, 0, 0, 0.22) 0px 3px 3px;
    border-top:6px solid #1da179;
}

.contactbox-links{
    font-family: 'Archivo Narrow';
    text-decoration:none;
    color:#1da179;
    font-size: 20px;
}

.contactbox-links:hover{
    color:#1da179;
    font-weight: 600;
}

.contactbox-review{
    position: absolute;
    left:25%;
}

.quotebox{
    position: absolute;
    right:5%;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 8px, rgba(0, 0, 0, 0.22) 0px 3px 3px;
    background-color:#fff;
    border-bottom:8px solid #1da179;
    padding:16px;
    color:#1da179;
    width:25%;
    border-radius:5px;
    font-family:'Archivo Narrow';
}

.pricingbtnrow{
    padding:16px;
    border:2px solid #eaeaea;
    display:flex;
    overflow-x: auto;
}

.pricingbtns{
    width:10%;
    font-size:24px;
    color:#fff;
    letter-spacing:3px;
    font-family:'Archivo Narrow';
    margin-left:12px;
}

.pricingbtns-mobile{
    font-size:20px;
    color:#fff;
    letter-spacing:3px;
    font-family:'Archivo Narrow';
    margin-left:4px;
}

.movers-btn:hover{
    background-color:#24dba4 !important;
}

.movers-btn:focus{
    background-color:#24dba4 !important;
}

.imagebox{
    overflow-x:auto;
}

.imagebox-mobile{
    overflow-x: auto;
}

.removebutton{
    transition: 500ms;
    cursor:pointer !important;
}

.removebutton:hover{
    background-color:#8B0000 !important;
    transition: 500ms;
}